import React from 'react'
import Navbar from '../components/navbar.jsx'
import Hero from '../img/hero-promo.jpg'
import VideoBgMp4 from '../assets/videoBg.mp4'
import VideoBgWebm from '../assets/videoBg.webm'
import VideoBgOgg from '../assets/videoBg.ogg'
import Title from '../img/title.png'
import TitleMobile from '../img/title-mobile.png'
import { Link } from 'react-router-dom';
import { FaDotCircle } from "react-icons/fa";
import Eventpop from '../img/icon-eventpop.svg';
import { FaYoutube } from "react-icons/fa";
import Partners from '../img/label-partners.png'
import Tdaa from '../img/logos/logo-tdaa.png'
import Tma from '../img/logos/logo-tma.png'
import Cm from '../img/logos/logo-cm.png'
import Ccc from '../img/logos/logo-ccc.png'
import Gm from '../img/logos/logo-gm.png'
import Rs from '../img/logos/logo-right-shift.png'


function hero() {
   return (
      <div>
         <Navbar />
         <div className='w-full h-screen mx-auto relative font-body flex items-center justify-center'>
            <img className='w-full h-full object-cover absolute' src={Hero} />
            {/* <video autoPlay="autoplay" loop="loop" muted playsInline poster={Hero}>
               <source src={VideoBgMp4} type="video/mp4" />
            </video> */}

            <div className='w-full flex flex-col absolute px-4'>
               <div className=' pt-32 text-white font-body mx-auto text-center max-md:pt-0'>
                  <h1 className='font-title font-bold text-5xl uppercase max-sm:text-2xl'>See you in January 23-26 2025</h1>
                  <p className='font-title text-3xl uppercase mt-2 max-sm:text-xl'>Chiangmai <span className='text-orange-500'>|</span> Thailand</p>
               </div>

               <div>
                  <div className='pt-10 max-lg:pt-10'>
                     <img className='w-[1800px] mx-auto max-lg:hidden' src={Title} />
                     <img className='w-[700px] mx-auto lg:hidden' src={TitleMobile} />
                  </div>
               </div>

               <div className='mx-auto flex flex-row items-center mt-10 max-md:mt-5 max-sm:flex-col'>
                  <Link to='https://www.eventpop.me/e/62266/https-blockmountaincnx-com' target="_blank">
                     <button-clip>
                        <img src={Eventpop} height={120} width={23} />
                        <p className='pl-3 text-white uppercase'>Buy Tickets</p>
                     </button-clip>
                  </Link>
                  <Link className='max-md:hidden' to='/speakersessions2024'>
                     <button-white className='flex items-center'><FaYoutube size={23} color="red" /> <p className='pl-3'>Watch Past Events</p></button-white>
                  </Link>

               </div>
            </div>

            <div className='w-full flex flex-col absolute inset-x-0 bottom-0 max-md:hidden'>
               <div className='grid grid-cols-6 font-body text-center text-white uppercase max-md:grid-cols-3 grow'>
                  <div className='pb-5'>
                     <h3 className='font-bold'>Blockchain</h3>
                     <p className='text-xs text-orange-500'>Technology</p>
                  </div>
                  <div className='border-l border-white pb-5'>
                     <h3 className='font-bold'>Crypto</h3>
                     <p className='text-xs text-orange-500'>Currency</p>
                  </div>

                  <div className='border-l border-white pb-5'>
                     <h3 className='font-bold'>NFT<span className='lowercase'>s</span></h3>
                     <p className='text-xs text-orange-500'>Metaverse</p>
                  </div>

                  <div className='border-l border-white pb-5 max-md:border-0'>
                     <h3 className='font-bold'>D<span className='lowercase'>e</span>F<span className='lowercase'>i</span></h3>
                     <p className='text-xs text-orange-500'>Fintech</p>
                  </div>

                  <div className='border-l border-white pb-5'>
                     <h3 className='font-bold'>Game Fi</h3>
                     <p className='text-xs text-orange-500'>Blockchain</p>
                  </div>

                  <div className='border-l border-white pb-5'>
                     <h3 className='font-bold'>Web3</h3>
                     <p className='text-xs text-orange-500'>DAO' <span className='lowercase'>s</span></p>
                  </div>
               </div>
            </div>

            {/* <div className='w-full flex flex-col absolute inset-x-0 bottom-0'>
               <div className='border-b-2 border-cyan-600'>
                  <img src={Partners} />

               </div>
               <div className='grid grid-cols-6 gap-5 place-items-center py-5 max-md:grid-cols-3 max-md:gap-y-5 max-md:gap-x-5 px-4'>
                  <div>
                     <img src={Tdaa}/>
                  </div>
                  <div>
                     <img src={Tma}/>
                  </div>
                  <div>
                     <img src={Cm}/>
                  </div>
                  <div>
                     <img src={Ccc}/>
                  </div>
                  <div>
                     <img className='max-lg:w-[85%] max-md:w-[100%] max-sm:w-[80%]' src={Gm}/>
                  </div>
                  <div>
                     <img src={Rs}/>
                  </div>
               </div>
            </div> */}
         </div>
      </div>

   );
}

export default hero