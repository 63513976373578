import React from 'react'
import { Link } from 'react-router-dom';

function sponsorapplicatons() {
   return (
      <div className='w-full font-body px-4 py-20'>
         <div className='max-w-[1280px] mx-auto'>
            <div className="w-full font-body text-white">
               <div className="max-w-[850px] mx-auto">
                  <div className="text-center">
                     <h1 className="text-5xl font-bold uppercase pb-5 max-md:text-4xl">2025 Sponsor Applications</h1>
                     <p className="pb-5">We offer a wide range of packages that achieve your company's goals and can fit every budget. Request a sponsorship and exhibitor package or reach out to us directly!</p>
                     <div className="w-[350px] mx-auto max-sm:w-[100%]">
                        <Link><button-gradient><p className=''
                           onClick={(e) => {
                              window.location.href = "mailto:info.blockmountaincnx@gmail.com";
                           }}
                        >Request Package</p></button-gradient></Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default sponsorapplicatons