import React from "react";
import { Link } from 'react-router-dom';
import SpeakerAppllications from '../components/speakerapplicatons.jsx'
import Footer from '../components/footer.jsx'
import ScrollToTop from '../components/scrolltotop.jsx'


import Speaker001 from "../img/speakers/speaker-001.png";
import Speaker002 from "../img/speakers/speaker-002.png";
import Speaker003 from "../img/speakers/speaker-003.png";
import Speaker004 from "../img/speakers/speaker-004.png";
import Speaker005 from "../img/speakers/speaker-005.png";
import Speaker006 from "../img/speakers/speaker-006.png";
import Speaker007 from "../img/speakers/speaker-007.png";

import Speaker008 from "../img/speakers/speaker-008.png";
import Speaker009 from "../img/speakers/speaker-009.png";
import Speaker010 from "../img/speakers/speaker-010.png";
import Speaker011 from "../img/speakers/speaker-011.png";
import Speaker012 from "../img/speakers/speaker-012.png";
import Speaker013 from "../img/speakers/speaker-013.png";
import Speaker014 from "../img/speakers/speaker-014.png";

import Speaker015 from "../img/speakers/speaker-015.png";
import Speaker016 from "../img/speakers/speaker-016.png";
import Speaker017 from "../img/speakers/speaker-017.png";
import Speaker018 from "../img/speakers/speaker-018.png";
import Speaker019 from "../img/speakers/speaker-019.png";
import Speaker020 from "../img/speakers/speaker-020.png";
import Speaker021 from "../img/speakers/speaker-021.png";

import Speaker022 from "../img/speakers/speaker-022.png";
import Speaker023 from "../img/speakers/speaker-023.png";
import Speaker024 from "../img/speakers/speaker-024.png";
import Speaker025 from "../img/speakers/speaker-025.png";
import Speaker026 from "../img/speakers/speaker-026.png";
import Speaker027 from "../img/speakers/speaker-027.png";
import Speaker028 from "../img/speakers/speaker-028.png";

import Speaker029 from "../img/speakers/speaker-029.png";
import Speaker030 from "../img/speakers/speaker-030.png";
import Speaker031 from "../img/speakers/speaker-031.png";
import Speaker032 from "../img/speakers/speaker-032.png";
import Speaker033 from "../img/speakers/speaker-033.png";
import Speaker034 from "../img/speakers/speaker-034.png";
import Speaker035 from "../img/speakers/speaker-035.png";

import Speaker036 from "../img/speakers/speaker-036.png";
import Speaker037 from "../img/speakers/speaker-037.png";
import Speaker038 from "../img/speakers/speaker-038.png";
import Speaker039 from "../img/speakers/speaker-039.png";
import Speaker040 from "../img/speakers/speaker-040.png";
import Speaker041 from "../img/speakers/speaker-041.png";
import Speaker042 from "../img/speakers/speaker-042.png";

function speakers() {
   return (
      <div className="w-full bg-gradient-to-r from-[#040227] via-[#1C005B] to-[#040227] font-body px-4 pt-20">
         <div className="max-w-[1280px] mx-auto">
            <ScrollToTop />
            <SpeakerAppllications />

            <h2 className="text-3xl font-bold text-white uppercase text-center pb-10 max-md:text-2xl max-md:pt-10 max-md:pb-5">
               Past Speakers
            </h2>
         </div>

         {/* Speakers */}
         <div className="max-w-[1280px] mx-auto pb-60 grid grid-cols-7 max-lg:grid-cols-4 max-md:grid-cols-3 max-sm:grid-cols-2">
            {/* 1-st row */}
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker001} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Dr. Natee <br />Thepphot
               </h3>
               <p className="text-cyan-500 text-sm">Block Mountain</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker002} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Dr. Pun <br />Thiengburanatham
               </h3>
               <p className="text-cyan-500 text-sm">บพท</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker003} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Supakrit <br />Boonsart
               </h3>
               <p className="text-cyan-500 text-sm">Bitcast</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker004} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Piriya <br />Samphantharak
               </h3>
               <p className="text-cyan-500 text-sm">Chalok.com</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker005} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Peerapat <br />Hankongkaew
               </h3>
               <p className="text-cyan-500 text-sm">Blockchain Review</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker006} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Nat <br />Chittamai
               </h3>
               <p className="text-cyan-500 text-sm">GM Learning Club</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker007} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Sanchai <br />Poplee
               </h3>
               <p className="text-cyan-500 text-sm">Cryptomind</p>
            </div>

            {/* 2-nd row */}
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker008} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Sukrit <br />Buanyakrai
               </h3>
               <p className="text-cyan-500 text-sm">Crypto City Connext</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker009} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Boonthep <br />Techarungruangkit
               </h3>
               <p className="text-cyan-500 text-sm">Krungsri Finnovate</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker010} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Pongwut <br />Praipaisalkit
               </h3>
               <p className="text-cyan-500 text-sm">Big Bang Theory</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker011} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Dr. Nalita <br />Nawaporn
               </h3>
               <p className="text-cyan-500 text-sm">Crypto City Connext</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker012} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Plakorn <br />Yodchomyan
               </h3>
               <p className="text-cyan-500 text-sm">Kulap</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker013} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Matichon <br />Maneegard
               </h3>
               <p className="text-cyan-500 text-sm">Vulture Prime</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker014} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Dr. Wichit <br />Saiklao
               </h3>
               <p className="text-cyan-500 text-sm">Chitbeer</p>
            </div>

            {/* 3-th row */}
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker015} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Panchita <br />Asavateeranun
               </h3>
               <p className="text-cyan-500 text-sm">OM Platform</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker016} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Dome <br />Charoenyot
               </h3>
               <p className="text-cyan-500 text-sm">Tokenine</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker017} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Kowin <br />Kulruechakorn
               </h3>
               <p className="text-cyan-500 text-sm">Central Group</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker018} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Amorndej <br />Kiripattananon
               </h3>
               <p className="text-cyan-500 text-sm">Om Platform</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker019} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Manatpong <br />Mangkhrai
               </h3>
               <p className="text-cyan-500 text-sm">NIA</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker020} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Nat <br />Weerawan
               </h3>
               <p className="text-cyan-500 text-sm">Beer Cat Lab</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker021} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Sitthinut <br />Haruanpuach
               </h3>
               <p className="text-cyan-500 text-sm">Inspex</p>
            </div>

            {/* 4-th row */}
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker022} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Pawoot Pom <br />Pongvitayapanu
               </h3>
               <p className="text-cyan-500 text-sm">Gash</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker023} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Tascha <br />Punyaneramitdee
               </h3>
               <p className="text-cyan-500 text-sm">INIT Capital</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker024} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Nut <br />Joongwong
               </h3>
               <p className="text-cyan-500 text-sm">Vice President of TUNA</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker025} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Palakorn <br />Yodchomyan
               </h3>
               <p className="text-cyan-500 text-sm">Kulap</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker026} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Nares <br />Laopannarai
               </h3>
               <p className="text-cyan-500 text-sm">Ricco Wealth</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker027} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Kannithi <br />Thongthanakul
               </h3>
               <p className="text-cyan-500 text-sm">Merkle Capital</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker028} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Sathapon <br />Phattanakhuha
               </h3>
               <p className="text-cyan-500 text-sm">Smart Contract</p>
            </div>

            {/* 5-th row */}
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker029} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Patarakorn <br />Nicharankun
               </h3>
               <p className="text-cyan-500 text-sm">Deep Invest</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker030} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Sumedt <br />Tjitpukdebodin
               </h3>
               <p className="text-cyan-500 text-sm">Valix Consulting</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker031} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Chanon <br />Jaratsuttikul
               </h3>
               <p className="text-cyan-500 text-sm">Forward</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker032} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Aanant <br />Sony
               </h3>
               <p className="text-cyan-500 text-sm">Wirtual</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker033} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Dr. Chanwit <br />Boonchuay
               </h3>
               <p className="text-cyan-500 text-sm">Simpli Finance Lab</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker034} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Taro <br />Lertwattanarak
               </h3>
               <p className="text-cyan-500 text-sm">J Ventures</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker035} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Monsak <br />Socharoentham
               </h3>
               <p className="text-cyan-500 text-sm">DGA</p>
            </div>

            {/* 6-th row */}
            <div className="flex flex-col justify-center items-center py-5">
               <img className="" src={Speaker036} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Prachya <br />Komanee
               </h3>
               <p className="text-cyan-500 text-sm">Depa</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker037} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Wisinee <br />Tanpichai
               </h3>
               <p className="text-cyan-500 text-sm">Crypro Queen</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker038} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Dr. Sittichai <br />Luangkittikong
               </h3>
               <p className="text-cyan-500 text-sm">Crypto City Connext</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker039} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Dr. Thuntee <br />Sukchotrat
               </h3>
               <p className="text-cyan-500 text-sm">D-Vote</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker040} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Dr. Deja <br />Piyavhatkul
               </h3>
               <p className="text-cyan-500 text-sm">Thai Nakarin Hospital</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker041} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Woraphot <br />Tharasiriskul
               </h3>
               <p className="text-cyan-500 text-sm">Kane Digital</p>
            </div>
            <div className="flex flex-col justify-center items-center py-5 ">
               <img className="" src={Speaker042} width={122} />
               <h3 className="text-white text-sm uppercase pt-3 text-center leading-4">
                  Phreeraphat <br />Pechdit
               </h3>
               <p className="text-cyan-500 text-sm">Wagyu Lab</p>
            </div>
         </div>
         <Footer />
      </div>
   );
}

export default speakers;
