import React from 'react'
import Marquee from "react-fast-marquee";

function tickerfast() {
   return (
      <div className='py-10 bg-gradient-to-r from-[#040227] via-[#1C005B] to-[#040227] font-body font-extrabold text-blue-800'>
         <Marquee className='h-[120px]'>
            <h1 className='text-8xl'>ENT SEE YOU IN EVENT  SEE YOU IN EVENT  SEE YOU IN EV </h1>
         </Marquee>
      </div>
   )
}

export default tickerfast