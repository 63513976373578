import React, { useState } from 'react'
import logo from '../img/logo.svg';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Navbar = () => {
   const [nav, setNav] = useState(false)
   const activeLink = 'text-green-700'
   const normalLink = ''

   const handleNav = () => {
      setNav(!nav);
   };

   return (
      <div className='w-full px-4 absolute z-40'>
         <div className='max-w-[1240px] mx-auto flex justify-between items-center h-24 text-sm font-medium uppercase font-body cursor-pointer'>
            <a href="/">
               <img className='mt-4' src={logo} height={100} width={170} />
            </a>
            <ul className='hidden md:flex items-center'>
               <NavLink exact to="./speakers"
                  className={({ isActive }) => isActive ? activeLink : normalLink}
               ><li className='p-2 text-white hover:text-orange-500 ease-out duration-500'>Speakers</li></NavLink>
               <NavLink exact to="./speakersessions2024"
                  className={({ isActive }) => isActive ? activeLink : normalLink}
               ><li className='p-2 text-white hover:text-orange-500 ease-out duration-500'>Speaker Session</li></NavLink>
               <NavLink exact to="./partners"
                  className={({ isActive }) => isActive ? activeLink : normalLink}
               ><li className='p-2 text-white hover:text-orange-500 ease-out duration-500'>Partners</li></NavLink>
               <div className='pl-5'>
                  <Link><button className='bg-orange-500 px-5 py-2 flex justify-center items-center rounded-md hover:bg-orange-600'>
                     <p className='text-white uppercase'
                        onClick={(e) => {
                           window.location.href = "mailto:info.blockmountaincnx@gmail.com";
                        }}
                     >Register Now</p></button></Link>
               </div>

            </ul>


            <div onClick={handleNav} className='md:hidden z-10'>
               {nav ? <AiOutlineClose size={20} color='white' /> : <AiOutlineMenu size={20} color='white' />}
            </div>

            <div
               onClick={handleNav}
               className={
                  nav
                     ? 'overflow-y-hidden md:hidden absolute left-0 top-0 w-full h-screen text-white font-normal border-r border-r-gray-900 bg-black ease-in-out duration-100'
                     : 'absolute top-0 h-full left-[-100%] ease-in-out duration-300'
               }
            >
               <img className='mt-4 ml-4' src={logo} height={100} width={170} />
               <ul className='pt-4'>
                  <NavLink exact to="./speakers"
                     className={({ isActive }) => isActive ? activeLink : normalLink}
                  ><li className='p-4 text-white border-b border-gray-900 hover:text-orange-500 ease-out duration-500'>Speakers</li></NavLink>
                  <NavLink exact to="./speakersessions2024"
                     className={({ isActive }) => isActive ? activeLink : normalLink}
                  ><li className='p-4 text-white border-b border-gray-900 hover:text-orange-500 ease-out duration-500'>Speaker Session</li></NavLink>
                  <NavLink exact to="./partners"
                     className={({ isActive }) => isActive ? activeLink : normalLink}
                  ><li className='p-4 text-white border-b border-gray-900 hover:text-orange-500 ease-out duration-500'>Partners</li></NavLink>
               </ul>
               <div className='pl-4 pt-4'>
                  <Link><button className='bg-orange-500 px-5 py-2 flex justify-center items-center rounded-md'>
                     <p className=' text-white uppercase'>Register Now</p></button></Link>
               </div>
            </div>
         </div >
      </div>
   )
}

export default Navbar;
