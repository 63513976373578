import React, { Component } from "react";
import Speaker_1 from "../img/quote-1.jpg";
import Speaker_2 from "../img/quote-2.jpg";
import Speaker_3 from "../img/quote-3.jpg";
import Speaker_4 from "../img/quote-4.jpg";

export class quote extends Component {
   render() {
      return (
         <div>
            <div className="bg-blue-800 px-4 font-body">
               <div className="max-w-[1280px] mx-auto">
                  <div className="flex justify-between max-md:flex-col">

                     {/* Photo */}
                     <div className="relative overflow-hidden max-md:pt-4">
                        <img
                           className="h-full object-cover hover:scale-110 hover:rotate-2 duration-500"
                           src={Speaker_1}
                        />
                     </div>

                     {/* Description */}
                     <div className="w-[50%] flex flex-col justify-center pl-5 max-md:w-[100%] max-md:pl-0 max-md:py-5">
                        <p className="text-white text-xl">
                           «Block Mountain bring together crypto-native and institutional
                           minds to produce high-quality and engaging discussions»
                        </p>
                        <div className="pt-5">
                           <h3 className="text-sm font-bold uppercase text-blue-200">
                              Dr. Natee Theppot
                           </h3>
                           <p className="text-sm text-blue-200">
                              Founder of Block Mountain Chairman of OM Platform Co. Ltd.
                           </p>
                        </div>
                     </div>
                  </div>

               </div>
            </div>

            <div className="bg-blue-700 px-4 font-body">
               <div className="max-w-[1280px] mx-auto">
                  <div className="flex justify-between max-md:flex-col-reverse">

                     {/* Description */}
                     <div className="w-[50%] flex flex-col justify-center pr-5 max-md:w-[100%] max-md:pr-0 max-md:py-5">
                        <p className="text-white text-xl">
                           «Block Mountain was a resounding success. I was impressed with the
                           consistently high caliber of talent»
                        </p>
                        <div className="pt-5">
                           <h3 className="text-sm font-bold uppercase text-blue-200">
                              Mr. Nares Laopannarai
                           </h3>
                           <p className="text-sm text-blue-200">
                              Founder of Ricco Wealth
                           </p>
                        </div>
                     </div>

                     {/* Photo */}
                     <div className="relative overflow-hidden max-md:pt-4">
                        <img
                           className="h-full object-cover hover:scale-110 hover:rotate-2 duration-500"
                           src={Speaker_3}
                        />
                     </div>
                  </div>

               </div>
            </div>


         </div>
      );
   }
}

export default quote;
