import React from 'react'
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom';
import Footer from '../components/footer.jsx'
import ScrollToTop from '../components/scrolltotop.jsx'

function speakersessions2020() {
   return (
      <div className='w-full bg-gradient-to-r from-[#040227] via-[#1C005B] to-[#040227] font-body px-4'>
         <div className='max-w-[1280px] mx-auto'>
            <ScrollToTop />

            {/* Navigation */}
            <div className="max-w-[1280px] mx-auto text-white grid grid-cols-6 gap-6 pt-40 max-md:grid-cols-3">
               <Link to='/speakersessions2019'><div className="flex justify-center bg-white bg-opacity-25 rounded-full py-2 cursor-pointer hover:bg-blue-700 hover:bg-opacity-100 ease-out duration-300">
                  <h3>2019</h3>
               </div></Link>

               <Link to='/speakersessions2020'><div className="flex justify-center bg-blue-700 rounded-full py-2 cursor-pointer hover:bg-opacity-100 ease-out duration-300">
                  <h3>2020</h3>
               </div></Link>

               <Link to='/speakersessions2021'><div className="flex justify-center bg-white bg-opacity-25 rounded-full py-2 cursor-pointer hover:bg-blue-700 hover:bg-opacity-100 ease-out duration-300">
                  <h3>2021</h3>
               </div></Link>

               <Link to='/speakersessions2022'><div className="flex justify-center bg-white bg-opacity-25 rounded-full py-2 cursor-pointer hover:bg-blue-700 hover:bg-opacity-100 ease-out duration-300">
                  <h3>2022</h3>
               </div></Link>

               <Link to='/speakersessions2023'><div className="flex justify-center bg-white bg-opacity-25 rounded-full py-2 cursor-pointer hover:bg-blue-700 hover:bg-opacity-100 ease-out duration-300">
                  <h3>2023</h3>
               </div></Link>

               <Link to='/speakersessions2024'><div className="flex justify-center bg-white bg-opacity-25 rounded-full py-2 cursor-pointer hover:bg-blue-700 hover:bg-opacity-100 ease-out duration-300">
                  <h3>2024</h3>
               </div></Link>
            </div>

            {/* Title */}
            <div className='items-center pt-20'>
               <h2 className='text-5xl font-bold text-white uppercase text-center'>Block Mountain 2020</h2>
            </div>

            {/* Main VDO */}
            <div className='pt-20 max-md:pt-10'>
               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/QBueD5DU0ek?si=7YiGOAT_gKBZaTsB'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>
            </div>

            {/* Title */}
            <div className='pt-20'>
               <h2 className='text-3xl font-bold text-white uppercase text-center'>Speaker Session</h2>
            </div>

            {/* Speaker Session */}
            <div className='grid grid-cols-3 gap-3 max-md:grid-cols-1 pt-10'>
               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/fkYsIMnQcqg?si=pN-wvkv79DVozOba'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/x0yTTJ7ibzk?si=qE2JKVb3LsJF7xC1'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/ev5KnWVHa4o?si=w5A0BikWbWkAZteN'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/4sbXEN2DFqI?si=BmPKTNaQm-3EbcKe'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/WdD7AtkdA-g?si=DNLWLNlkbuc9SvUO'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/vVCbCCYGMCo?si=ABMDN9FzqQc3MV9M'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/3V_NjR-0N08?si=DjxEt7JGOBGGGX3O'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/6fXQu9ONm8o?si=x5LGvODwLWb1tIFp'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/UkpkaC-RGvM?si=Ci3vQnMTSvJ9G0fE'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/WW_y9ggs288?si=ZEvP3fqNDgk6ywOB'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/uAWzAIu-VKU?si=1m41qGXqYgEEf3Va'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/UccbNVe2CHE?si=UwVpxsz868m_r2O9'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/BXaHgCKRQIE?si=z2iY-6cdWq2tjTpf'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/cyjjTFwvzlY?si=Z1B_FagXc_H6j7df'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/rJe9TRQLyec?si=bLBx-x-HUKCdehWX'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/Y7wO11QvzPo?si=vRhEypLMySXz5idZ'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

               <div className='player-wrapper'>
                  <ReactPlayer
                     className='react-player px-2 py-2'
                     url='https://youtu.be/8nuaytydqok?si=_XdB6lwPO6T0A8Ox'

                     width="100%"
                     height="100%"
                     controls={false}
                  />
               </div>

            </div>
         </div>

         <Footer />
      </div>
   )
}

export default speakersessions2020