import React from "react";
import { FaFacebook, FaYoutube } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

function footer() {
  return (
    <div className="w-full bg-gradient-to-r from-[#040227] via-[#1C005B] to-[#040227] font-body px-4 pb-5 pt-20">
      <div className="max-w-[1280px] mx-auto flex justify-between border-t border-[#484676] pt-5">
        <div>
          <h3 className="text-cyan-500 uppercase font-medium mb-1">Menu</h3>
          <Link to='/speakers'><p className="text-sm text-indigo-300 pt-1 hover:text-indigo-200 ease-out duration-500">Speakers</p></Link>
          <Link to='/speakersessions2024'><p className="text-sm text-indigo-300 pt-1 hover:text-indigo-200 ease-out duration-500">Speaker Session</p></Link>
          <Link to='/partners'><p className="text-sm text-indigo-300 pt-1 hover:text-indigo-200 ease-out duration-500">Partners</p></Link>
        </div>

        <div className="flex max-md:flex-col max-md:pl-4">
          <div>
            <h3 className="text-cyan-500 uppercase font-medium mb-1">
              Get Involved
            </h3>
            <Link to='/partners'><p className="text-sm text-indigo-300 pt-1 hover:text-indigo-200 ease-out duration-500">Sponsors & Booths</p></Link>
            <Link to='/speakers'><p className="text-sm text-indigo-300 pt-1 hover:text-indigo-200 ease-out duration-500">Speaker Application</p></Link>
            <Link><p className="text-sm text-indigo-300 pt-1 hover:text-indigo-200 ease-out duration-500">Food & Accommodation</p></Link>
          </div>
          <div className="pl-40 max-md:pl-0 max-md:pt-10">
            <h3 className="text-cyan-500 uppercase font-medium mb-1">
              Contact Us
            </h3>
            <div
              className="text-white hover:text-indigo-200 hover:underline hover:underline-offset-4 cursor-pointer text-sm pt-1 max-md:text-xs"
              onClick={(e) => {
                window.location.href = "mailto:info.blockmountaincnx@gmail.com";
              }}
            >
              info.blockmountaincnx@gmail.com
            </div>

            <div className="flex items-center">
              <NavLink
                to="https://www.facebook.com/BlockmountainOfficial"
                target="_blank"
              >
                <div className="pt-3 cursor-pointer">
                  <FaFacebook
                    size={27}
                    color="#ffff"
                    onMouseOver={({ target }) =>
                      (target.style.color = "#818cf8")
                    }
                    onMouseOut={({ target }) => (target.style.color = "#ffff")}
                  />
                </div>
              </NavLink>
              <NavLink
                to="https://www.youtube.com/@blockmountain"
                target="_blank"
              >
                <div className="pt-3 pl-3 cursor-pointer">
                  <FaYoutube
                    size={30}
                    color="#ffff"
                    onMouseOver={({ target }) =>
                      (target.style.color = "#818cf8")
                    }
                    onMouseOut={({ target }) => (target.style.color = "#ffff")}
                  />
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[1280px] mx-auto flex justify-start max-md:justify-center">
        <p className="text-xs font-bold text-[#484676] pt-10">© 2025 BLOCK MOUNTAIN All Rights Reserved</p>
      </div>
    </div>
  );
}

export default footer;
