import React from 'react'
import Sponsor01 from '../img/sponsors/sponsor-01.jpg'
import Sponsor02 from '../img/sponsors/sponsor-02.jpg'
import Sponsor03 from '../img/sponsors/sponsor-03.jpg'
import Sponsor04 from '../img/sponsors/sponsor-04.jpg'
import Sponsor05 from '../img/sponsors/sponsor-05.jpg'
import Sponsor06 from '../img/sponsors/sponsor-06.jpg'
import Sponsor07 from '../img/sponsors/sponsor-07.jpg'
import Sponsor08 from '../img/sponsors/sponsor-08.jpg'
import Sponsor09 from '../img/sponsors/sponsor-09.jpg'
import Sponsor010 from '../img/sponsors/sponsor-010.jpg'
import Sponsor011 from '../img/sponsors/sponsor-011.jpg'
import Sponsor012 from '../img/sponsors/sponsor-012.jpg'
import Sponsor013 from '../img/sponsors/sponsor-013.jpg'
import Sponsor014 from '../img/sponsors/sponsor-014.jpg'
import Sponsor015 from '../img/sponsors/sponsor-015.jpg'
import Sponsor016 from '../img/sponsors/sponsor-016.jpg'

import Sponsor017 from '../img/sponsors/sponsor-017.jpg'
import Sponsor018 from '../img/sponsors/sponsor-018.jpg'
import Sponsor019 from '../img/sponsors/sponsor-019.jpg'
import Sponsor020 from '../img/sponsors/sponsor-020.jpg'
import Sponsor021 from '../img/sponsors/sponsor-021.jpg'
import Sponsor022 from '../img/sponsors/sponsor-022.jpg'
import Sponsor023 from '../img/sponsors/sponsor-023.jpg'
import Sponsor024 from '../img/sponsors/sponsor-024.jpg'
import Sponsor025 from '../img/sponsors/sponsor-025.jpg'
import Sponsor026 from '../img/sponsors/sponsor-026.jpg'
import Sponsor027 from '../img/sponsors/sponsor-027.jpg'
import Sponsor028 from '../img/sponsors/sponsor-028.jpg'
import Sponsor029 from '../img/sponsors/sponsor-029.jpg'

import Sponsor030 from '../img/sponsors/sponsor-030.jpg'
import Sponsor031 from '../img/sponsors/sponsor-031.jpg'
import Sponsor032 from '../img/sponsors/sponsor-032.jpg'
import Sponsor033 from '../img/sponsors/sponsor-033.jpg'

import Sponsor035 from '../img/sponsors/sponsor-035.jpg'
import Sponsor036 from '../img/sponsors/sponsor-036.jpg'
import Sponsor037 from '../img/sponsors/sponsor-037.jpg'
import Sponsor038 from '../img/sponsors/sponsor-038.jpg'
import Sponsor039 from '../img/sponsors/sponsor-039.jpg'
import Sponsor040 from '../img/sponsors/sponsor-040.jpg'
import Sponsor041 from '../img/sponsors/sponsor-041.jpg'
import Sponsor042 from '../img/sponsors/sponsor-042.jpg'
import Sponsor043 from '../img/sponsors/sponsor-043.jpg'
import Sponsor044 from '../img/sponsors/sponsor-044.jpg'
import Sponsor045 from '../img/sponsors/sponsor-045.jpg'
import Sponsor046 from '../img/sponsors/sponsor-046.jpg'
import Sponsor047 from '../img/sponsors/sponsor-047.jpg'
import Sponsor048 from '../img/sponsors/sponsor-048.jpg'
import Sponsor049 from '../img/sponsors/sponsor-049.jpg'
import Sponsor050 from '../img/sponsors/sponsor-050.jpg'

import Sponsor051 from '../img/sponsors/sponsor-051.jpg'
import Sponsor052 from '../img/sponsors/sponsor-052.jpg'
import Sponsor053 from '../img/sponsors/sponsor-053.jpg'
import Sponsor054 from '../img/sponsors/sponsor-054.jpg'
import Sponsor055 from '../img/sponsors/sponsor-055.jpg'
import Sponsor056 from '../img/sponsors/sponsor-056.jpg'
import Sponsor057 from '../img/sponsors/sponsor-057.jpg'
import Sponsor058 from '../img/sponsors/sponsor-058.jpg'
import Sponsor059 from '../img/sponsors/sponsor-059.jpg'
import Sponsor060 from '../img/sponsors/sponsor-060.jpg'
import Sponsor061 from '../img/sponsors/sponsor-061.jpg'
import Sponsor062 from '../img/sponsors/sponsor-062.jpg'
import Sponsor063 from '../img/sponsors/sponsor-063.jpg'
import Sponsor064 from '../img/sponsors/sponsor-064.jpg'
import Sponsor065 from '../img/sponsors/sponsor-065.jpg'
import Sponsor066 from '../img/sponsors/sponsor-066.jpg'
import Sponsor067 from '../img/sponsors/sponsor-067.jpg'
import Sponsor068 from '../img/sponsors/sponsor-068.jpg'
import Sponsor069 from '../img/sponsors/sponsor-069.jpg'
import Sponsor070 from '../img/sponsors/sponsor-070.jpg'
import Sponsor071 from '../img/sponsors/sponsor-071.jpg'
import Sponsor072 from '../img/sponsors/sponsor-072.jpg'

import Sponsor073 from '../img/sponsors/sponsor-073.jpg'
import Sponsor074 from '../img/sponsors/sponsor-074.jpg'
import Sponsor075 from '../img/sponsors/sponsor-075.jpg'
import Sponsor076 from '../img/sponsors/sponsor-076.jpg'
import Sponsor077 from '../img/sponsors/sponsor-077.jpg'
import Sponsor078 from '../img/sponsors/sponsor-078.jpg'
import Sponsor079 from '../img/sponsors/sponsor-079.jpg'
import Sponsor080 from '../img/sponsors/sponsor-080.jpg'
import Sponsor081 from '../img/sponsors/sponsor-081.jpg'
import Sponsor082 from '../img/sponsors/sponsor-082.jpg'
import Sponsor083 from '../img/sponsors/sponsor-083.jpg'
import Sponsor084 from '../img/sponsors/sponsor-084.jpg'
import Sponsor085 from '../img/sponsors/sponsor-085.jpg'
import Sponsor086 from '../img/sponsors/sponsor-086.jpg'
import Sponsor087 from '../img/sponsors/sponsor-087.jpg'
import Sponsor088 from '../img/sponsors/sponsor-088.jpg'
import Sponsor089 from '../img/sponsors/sponsor-089.jpg'
import Sponsor090 from '../img/sponsors/sponsor-090.jpg'
import Sponsor091 from '../img/sponsors/sponsor-091.jpg'
import Sponsor092 from '../img/sponsors/sponsor-092.jpg'
import Sponsor093 from '../img/sponsors/sponsor-093.jpg'
import Sponsor094 from '../img/sponsors/sponsor-094.jpg'
import Sponsor095 from '../img/sponsors/sponsor-095.jpg'
import Sponsor096 from '../img/sponsors/sponsor-096.jpg'
import Sponsor097 from '../img/sponsors/sponsor-097.jpg'
import Sponsor098 from '../img/sponsors/sponsor-098.jpg'
import Sponsor099 from '../img/sponsors/sponsor-099.jpg'
import Sponsor100 from '../img/sponsors/sponsor-100.jpg'
import Sponsor101 from '../img/sponsors/sponsor-101.jpg'
import Sponsor102 from '../img/sponsors/sponsor-102.jpg'
import Sponsor103 from '../img/sponsors/sponsor-103.jpg'
import Sponsor104 from '../img/sponsors/sponsor-104.jpg'
import Sponsor105 from '../img/sponsors/sponsor-105.jpg'
import Sponsor106 from '../img/sponsors/sponsor-106.jpg'
import Sponsor107 from '../img/sponsors/sponsor-107.jpg'
import Sponsor108 from '../img/sponsors/sponsor-108.jpg'
import Sponsor109 from '../img/sponsors/sponsor-109.jpg'
import Sponsor110 from '../img/sponsors/sponsor-110.jpg'
import Sponsor111 from '../img/sponsors/sponsor-111.jpg'
import Sponsor112 from '../img/sponsors/sponsor-112.jpg'
import Sponsor113 from '../img/sponsors/sponsor-113.jpg'
import Sponsor114 from '../img/sponsors/sponsor-114.jpg'
import Sponsor115 from '../img/sponsors/sponsor-115.jpg'
import Sponsor116 from '../img/sponsors/sponsor-116.jpg'
import Sponsor117 from '../img/sponsors/sponsor-117.jpg'
import Sponsor118 from '../img/sponsors/sponsor-118.jpg'
import Sponsor119 from '../img/sponsors/sponsor-119.jpg'
import Sponsor120 from '../img/sponsors/sponsor-120.jpg'
import Sponsor121 from '../img/sponsors/sponsor-121.jpg'
import Sponsor122 from '../img/sponsors/sponsor-122.jpg'
import Sponsor123 from '../img/sponsors/sponsor-123.jpg'
import Sponsor124 from '../img/sponsors/sponsor-124.jpg'
import Sponsor125 from '../img/sponsors/sponsor-125.jpg'
import Sponsor126 from '../img/sponsors/sponsor-126.jpg'
import Sponsor127 from '../img/sponsors/sponsor-127.jpg'
import Sponsor128 from '../img/sponsors/sponsor-128.jpg'
import Sponsor129 from '../img/sponsors/sponsor-129.jpg'
import Sponsor130 from '../img/sponsors/sponsor-130.jpg'
import Sponsor131 from '../img/sponsors/sponsor-131.jpg'
import Sponsor132 from '../img/sponsors/sponsor-132.jpg'
import Sponsor133 from '../img/sponsors/sponsor-133.jpg'
import Sponsor134 from '../img/sponsors/sponsor-134.jpg'
import Sponsor135 from '../img/sponsors/sponsor-135.jpg'
import Sponsor136 from '../img/sponsors/sponsor-136.jpg'
import Sponsor137 from '../img/sponsors/sponsor-137.jpg'
import Sponsor138 from '../img/sponsors/sponsor-138.jpg'
import Sponsor139 from '../img/sponsors/sponsor-139.jpg'
import Sponsor140 from '../img/sponsors/sponsor-140.jpg'
import Sponsor141 from '../img/sponsors/sponsor-141.jpg'
import Sponsor142 from '../img/sponsors/sponsor-142.jpg'
import Sponsor143 from '../img/sponsors/sponsor-143.jpg'
import Sponsor144 from '../img/sponsors/sponsor-144.jpg'
import Sponsor145 from '../img/sponsors/sponsor-145.jpg'
import Sponsor146 from '../img/sponsors/sponsor-146.jpg'
import Sponsor147 from '../img/sponsors/sponsor-147.jpg'
import Sponsor148 from '../img/sponsors/sponsor-148.jpg'
import Sponsor149 from '../img/sponsors/sponsor-149.jpg'
import Sponsor150 from '../img/sponsors/sponsor-150.jpg'
import Sponsor151 from '../img/sponsors/sponsor-151.jpg'
import Sponsor152 from '../img/sponsors/sponsor-152.jpg'
import Sponsor153 from '../img/sponsors/sponsor-153.jpg'
import Sponsor154 from '../img/sponsors/sponsor-154.jpg'
import Sponsor155 from '../img/sponsors/sponsor-155.jpg'
import Sponsor156 from '../img/sponsors/sponsor-156.jpg'
import Sponsor157 from '../img/sponsors/sponsor-157.jpg'
import Sponsor158 from '../img/sponsors/sponsor-158.jpg'








function sponsors() {
   return (
      <div className='w-full bg-slate-100 font-body px-4 py-10'>
         <div className='max-w-[1280px] mx-auto flex flex-col text-center'>
            <div className='pb-5'>
               <h2 className='text-4xl font-extrabold uppercase text-[#110066]'>2025 Event Sponsors </h2>
            </div>
            {/* Host */}
            <div className='mx-auto'>
               <h3 className='uppercase font-bold text-lg text-[#110066] pb-5'>Host</h3>
               <img className='px-1 py-1' src={Sponsor01} />
            </div>

            {/* Partners */}
            <div className='mx-auto py-5'>
               <h3 className='uppercase font-bold text-lg text-[#110066] pb-5'>Partners</h3>
               <div className='grid grid-cols-5 max-md:grid-cols-3 max-sm:grid-cols-2'>
                  <img className='px-1 py-1' src={Sponsor02} />
                  <img className='px-1 py-1' src={Sponsor03} />
                  <img className='px-1 py-1' src={Sponsor04} />
                  <img className='px-1 py-1' src={Sponsor05} />
                  <img className='px-1 py-1' src={Sponsor012} />
                  <img className='px-1 py-1' src={Sponsor013} />
                  <img className='px-1 py-1' src={Sponsor116} />
                  <img className='px-1 py-1' src={Sponsor117} />
                  <img className='px-1 py-1' src={Sponsor118} />

               </div>
            </div>

            {/* Diamond Sponsors */}
            <div className='mx-auto py-5'>
               <h3 className='uppercase font-bold text-lg text-[#110066] pb-5'>Diamond Sponsors</h3>
               <div className='grid grid-cols-2 max-md:grid-cols-2'>
                  <img className='px-1 py-1' src={Sponsor014} />
                  <img className='px-1 py-1' src={Sponsor119} />
               </div>
            </div>

            {/* Platinum Sponsors */}
            <div className='mx-auto py-5'>
               <h3 className='uppercase font-bold text-lg text-[#110066] pb-5'>Platinum Sponsors</h3>
               <div className='grid grid-cols-3 max-md:grid-cols-2'>
                  <img className='px-1 py-1' src={Sponsor120} />
                  <img className='px-1 py-1' src={Sponsor124} />
                  <img className='px-1 py-1' src={Sponsor151} />

               </div>
            </div>

            {/* Gold Sponsors */}
            <div className='mx-auto py-5'>
               <h3 className='uppercase font-bold text-lg text-[#110066] pb-5'>Gold Sponsors</h3>
               <div className='grid grid-cols-6 max-md:grid-cols-3 max-sm:grid-cols-2'>
                  <img className='px-1 py-1' src={Sponsor121} />
                  <img className='px-1 py-1' src={Sponsor122} />
                  <img className='px-1 py-1' src={Sponsor123} />
                  <img className='px-1 py-1' src={Sponsor021} />
                  <img className='px-1 py-1' src={Sponsor126} />
                  <img className='px-1 py-1' src={Sponsor127} />

               </div>
            </div>

            {/* Community | Contributors */}
            <div className='mx-auto py-5'>
               <h3 className='uppercase font-bold text-lg text-[#110066] pb-5'>Community | Contributors</h3>
               <div className='grid grid-cols-7 max-md:grid-cols-3 max-sm:grid-cols-2'>
                  <img className='px-1 py-1' src={Sponsor09} />
                  <img className='px-1 py-1' src={Sponsor011} />
                  <img className='px-1 py-1' src={Sponsor030} />
                  <img className='px-1 py-1' src={Sponsor031} />
                  <img className='px-1 py-1' src={Sponsor039} />
                  <img className='px-1 py-1' src={Sponsor027} />
                  <img className='px-1 py-1' src={Sponsor033} />

                  <img className='px-1 py-1' src={Sponsor036} />
                  <img className='px-1 py-1' src={Sponsor038} />
                  <img className='px-1 py-1' src={Sponsor026} />
                  <img className='px-1 py-1' src={Sponsor016} />
                  <img className='px-1 py-1' src={Sponsor145} />
                  <img className='px-1 py-1' src={Sponsor048} />
                  <img className='px-1 py-1' src={Sponsor042} />

                  <img className='px-1 py-1' src={Sponsor043} />
                  <img className='px-1 py-1' src={Sponsor032} />
                  <img className='px-1 py-1' src={Sponsor045} />
                  <img className='px-1 py-1' src={Sponsor040} />
                  <img className='px-1 py-1' src={Sponsor067} />
                  <img className='px-1 py-1' src={Sponsor069} />
                  <img className='px-1 py-1' src={Sponsor059} />

                  <img className='px-1 py-1' src={Sponsor057} />
                  <img className='px-1 py-1' src={Sponsor148} />
                  <img className='px-1 py-1' src={Sponsor149} />
                  <img className='px-1 py-1' src={Sponsor058} />
                  <img className='px-1 py-1' src={Sponsor072} />
                  <img className='px-1 py-1' src={Sponsor060} />
                  <img className='px-1 py-1' src={Sponsor056} />

                  <img className='px-1 py-1' src={Sponsor062} />
                  <img className='px-1 py-1' src={Sponsor128} />
                  <img className='px-1 py-1' src={Sponsor129} />
                  <img className='px-1 py-1' src={Sponsor130} />
                  <img className='px-1 py-1' src={Sponsor131} />
                  <img className='px-1 py-1' src={Sponsor132} />
                  <img className='px-1 py-1' src={Sponsor133} />

                  <img className='px-1 py-1' src={Sponsor134} />
                  <img className='px-1 py-1' src={Sponsor135} />
                  <img className='px-1 py-1' src={Sponsor150} />
                  <img className='px-1 py-1' src={Sponsor055} />
                  <img className='px-1 py-1' src={Sponsor06} />
                  <img className='px-1 py-1' src={Sponsor015} />
                  <img className='px-1 py-1' src={Sponsor146} />

                  <img className='px-1 py-1' src={Sponsor152} />
                  <img className='px-1 py-1' src={Sponsor153} />
                  <img className='px-1 py-1' src={Sponsor154} />

               </div>
            </div>

            {/* Media Partners */}
            <div className='mx-auto py-5'>
               <h3 className='uppercase font-bold text-lg text-[#110066] pb-5'>Media Partners</h3>
               <div className='grid grid-cols-7 max-md:grid-cols-3 max-sm:grid-cols-2'>
                  <img className='px-1 py-1' src={Sponsor073} />
                  <img className='px-1 py-1' src={Sponsor074} />
                  <img className='px-1 py-1' src={Sponsor108} />
                  <img className='px-1 py-1' src={Sponsor016} />
                  <img className='px-1 py-1' src={Sponsor104} />
                  <img className='px-1 py-1' src={Sponsor078} />
                  <img className='px-1 py-1' src={Sponsor147} />

                  <img className='px-1 py-1' src={Sponsor076} />
                  <img className='px-1 py-1' src={Sponsor081} />
                  <img className='px-1 py-1' src={Sponsor088} />
                  <img className='px-1 py-1' src={Sponsor095} />
                  <img className='px-1 py-1' src={Sponsor136} />
                  <img className='px-1 py-1' src={Sponsor087} />
                  <img className='px-1 py-1' src={Sponsor079} />

                  <img className='px-1 py-1' src={Sponsor090} />
                  <img className='px-1 py-1' src={Sponsor089} />
                  <img className='px-1 py-1' src={Sponsor093} />
                  <img className='px-1 py-1' src={Sponsor139} />
                  <img className='px-1 py-1' src={Sponsor140} />
                  <img className='px-1 py-1' src={Sponsor141} />
                  <img className='px-1 py-1' src={Sponsor158} />

                  <img className='px-1 py-1' src={Sponsor097} />
                  <img className='px-1 py-1' src={Sponsor155} />
                  <img className='px-1 py-1' src={Sponsor156} />
                  <img className='px-1 py-1' src={Sponsor086} />
                  <img className='px-1 py-1' src={Sponsor103} />
                  <img className='px-1 py-1' src={Sponsor105} />
                  <img className='px-1 py-1' src={Sponsor115} />

                  <img className='px-1 py-1' src={Sponsor137} />
                  <img className='px-1 py-1' src={Sponsor157} />
                  <img className='px-1 py-1' src={Sponsor138} />
                  <img className='px-1 py-1' src={Sponsor106} />
                  <img className='px-1 py-1' src={Sponsor085} />
                  <img className='px-1 py-1' src={Sponsor080} />
                  <img className='px-1 py-1' src={Sponsor109} />

                  <img className='px-1 py-1' src={Sponsor142} />
                  <img className='px-1 py-1' src={Sponsor143} />
                  <img className='px-1 py-1' src={Sponsor066} />
                  <img className='px-1 py-1' src={Sponsor110} />
                  <img className='px-1 py-1' src={Sponsor082} />
                  <img className='px-1 py-1' src={Sponsor107} />
                  <img className='px-1 py-1' src={Sponsor083} />

                  <img className='px-1 py-1' src={Sponsor084} />
                  <img className='px-1 py-1' src={Sponsor144} />





               </div>
            </div>

         </div>
      </div>
   )
}

export default sponsors