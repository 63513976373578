import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Photo1 from '../img/image-slider/image-slider-01.jpg'
import Photo2 from '../img/image-slider/image-slider-02.jpg'
import Photo3 from '../img/image-slider/image-slider-03.jpg'
import Photo4 from '../img/image-slider/image-slider-04.jpg'
import Photo5 from '../img/image-slider/image-slider-05.jpg'
import Photo6 from '../img/image-slider/image-slider-06.jpg'
import Photo7 from '../img/image-slider/image-slider-07.jpg'
import Photo8 from '../img/image-slider/image-slider-08.jpg'
import Photo9 from '../img/image-slider/image-slider-09.jpg'
import Photo10 from '../img/image-slider/image-slider-010.jpg'
import Photo11 from '../img/image-slider/image-slider-011.jpg'
import Photo12 from '../img/image-slider/image-slider-012.jpg'
import Photo13 from '../img/image-slider/image-slider-013.jpg'
import Photo14 from '../img/image-slider/image-slider-014.jpg'
import Photo15 from '../img/image-slider/image-slider-015.jpg'
import Photo16 from '../img/image-slider/image-slider-016.jpg'
import Photo17 from '../img/image-slider/image-slider-017.jpg'
import Photo18 from '../img/image-slider/image-slider-018.jpg'
import Photo19 from '../img/image-slider/image-slider-019.jpg'
import Photo20 from '../img/image-slider/image-slider-020.jpg'
import Photo21 from '../img/image-slider/image-slider-021.jpg'
import Photo22 from '../img/image-slider/image-slider-022.jpg'
import Photo23 from '../img/image-slider/image-slider-023.jpg'
import Photo24 from '../img/image-slider/image-slider-024.jpg'
import Photo25 from '../img/image-slider/image-slider-025.jpg'



function photoslider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,

    autoplay: true,
    pauseOnHover: true,
    speed: 2000,
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1675,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        }
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        }
      }
    ]
  };


  return (
    <div className='mx-auto my-10 max-md:px-4'>

      <Slider {...settings}>

        <div className='pl-1'>
          <img src={Photo1} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo2} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo3} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo4} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo5} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo6} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo7} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo8} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo9} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo10} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo11} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo12} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo13} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo14} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo15} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo16} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo17} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo18} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo19} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo20} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo21} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo22} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo23} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo24} className='' />
        </div>
        <div className='pl-1'>
          <img src={Photo25} className='' />
        </div>

      </Slider>
    </div>
  )
}


export default photoslider